export const useCharterDestinationStore = defineStore('charter-destination', {
  state: () => ({
    charterDestinationTitle: '',
    charterDestination: {},
    charterDestinationSections: [],
    charterDestinationPath: '',
    charterDestinationTab: 'info',
    charterDestinationTabs: [],
    charterDestinationTabVisited: {
      info: false,
      hotel: false,
      area: false,
      service: false,
      beach: false,
      activities: false,
      weather: false,
    },
    bannerCharterDestination: {},
    loading: false,
  }),
  getters: {
    GET_CHARTER_DESTINATION_ID (state) {
      return state.charterDestination.id || null
    },

    GET_CHARTER_DESTINATION (state) {
      return state.charterDestination
    },

    GET_CHARTER_DESTINATION_SECTIONS (state) {
      return state.charterDestinationSections
    },
  },
  actions: {
    SET_CHARTER_DESTINATION_TITLE (data) {
      this.charterDestinationTitle = data
    },

    SET_CHARTER_DESTINATION (data) {
      this.charterDestination = data
    },

    SET_CHARTER_DESTINATION_SECTIONS (data) {
      this.charterDestinationSections = data
    },

    SET_CHARTER_DESTINATION_PATH (data) {
      this.charterDestinationPath = data
    },

    SET_CHARTER_DESTINATION_TABS (data) {
      this.charterDestinationTabs = data
    },

    SET_CHARTER_DESTINATION_TAB (data) {
      this.charterDestinationTab = data
    },

    SET_CHARTER_DESTINATION_TAB_VISITED (data) {
      this.charterDestinationTabVisited = data
    },

    SET_BANNER_CHARTER_DESTINATION (data) {
      this.bannerCharterDestination = data
    },

    SET_LOADING (data) {
      this.loading = data
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCharterDestinationStore, import.meta.hot))
}